<template>
    <v-dialog
            v-model="show"
            max-width="40%">
        <template v-slot:activator="{ on, attrs }">
            <v-icon
                    v-bind="attrs"
                    v-on="on"
                    small
                    class="mr-2"
                    @click="editUser">
                mdi-pencil
            </v-icon>
            <v-icon
                    v-bind="attrs"
                    v-on="on"
                    small
                    @click="deleteUser">
                mdi-delete
            </v-icon>
        </template>
        <v-card >
            <div style="position: absolute; align-self: end; right: 0">
                <v-btn
                        icon
                        @click="show = !show"
                        class="ma-1" >
                    <v-icon color="red">mdi-window-close</v-icon>
                </v-btn>
            </div>
            <v-card-title
                    class="justify-center">
                {{formTitle}}
            </v-card-title>
            <v-divider></v-divider>
            <v-progress-linear
                    v-show="loading"
                    indeterminate
                    color="primary"
                    background-opacity="0">
            </v-progress-linear>
            <v-card-text v-if="actionType==='edit'">
                <v-form
                        ref="editUserForm"
                        lazy-validation
                        class="px-6">
                    <v-text-field
                            v-model="user.name"
                            label="Full Name"
                            :rules="rules.nameRules"
                            required>
                    </v-text-field>
                    <v-text-field
                            v-model="user.email"
                            label="E-mail"
                            :rules="rules.emailRules"
                            required>
                    </v-text-field>
                    <v-select
                            v-model="user.userRole"
                            :items="roles"
                            :rules="[v => !!v || 'User role is required']"
                            label="Select User Role"
                            required>
                    </v-select>
                    <!-- <v-select
                            v-model="user.assignedKiosks"
                            :rules="[v => !!v || 'Assign at-least one kiosk']"
                            :items="kiosks"
                            @keyup.enter="updateUser"
                            label="Assigned Kiosks"
                            required
                            multiple>
                        <template v-slot:selection="{ item, index }">
                            <v-chip small v-if="index === 0">
                                <span>{{ item }}</span>
                            </v-chip>
                            <span
                                    v-if="index === 1"
                                    class="grey--text caption">
                                (+{{ user.assignedKiosks.length - 1 }} others)
                            </span>
                        </template>
                    </v-select> -->
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                                color="primary"
                                text
                                @click="show = !show">
                            Cancel
                        </v-btn>
                        <v-btn
                                color="primary"
                                text
                                @click="updateUser">
                            Save
                        </v-btn>
                    </v-card-actions>
                </v-form>
            </v-card-text>
            <v-card-actions v-if="actionType==='delete'">
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="show = ! show">Cancel</v-btn>
                <v-btn color="blue darken-1" text @click="confirmDeleteUser">OK</v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
    </v-dialog>

</template>

<script>
  import {mapGetters} from 'vuex';

  export default {
    name: 'editAndDeleteUser',
    props:{
      userIndex: Object,
      userType: String
    },
    data(){
      return{
        show: null,
        actionType: '',
        formTitle: '',
        loading: false,
        user:{
          name: '',
          email: '',
          userRole: '',
          assignedKiosks: [],
          id: null,
        },
        rules:{
          nameRules: [
            v => !!v || 'Full name is required',
          ],
          emailRules: [
            v => !!v || 'E-mail is required',
            v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
          ],
        }
      }
    },
    methods:{
      editUser(){
        this.formTitle = "Edit User";
        this.actionType = 'edit';
        //this.editUserIndex = this.allRegisteredUsers.indexOf(this.user);
        this.user = Object.assign({}, this.userIndex);
        this.user.id= this.userIndex.id;
      },
      deleteUser(){
        this.formTitle = "Are you sure you want to delete this user?";
        this.actionType = 'delete';
      },
      updateUser(){
        if(this.$refs.editUserForm.validate()){
          this.loading = true;
          if(this.userType === 'registered'){
            this.$store.dispatch('updateRegisteredUserInformation', this.user)
              .then(response => {
                this.loading = false;
                this.show=false;
                this.$store.dispatch('showSnackBar', {
                  text: response,
                  color: 'success'
                })
              }, error => {
                this.$store.dispatch('showSnackBar', {
                  text: error,
                  color: 'error'
                })
              })
          }
          if(this.userType === 'invited'){
            this.$store.dispatch('updateInvitedUserInformation', this.user)
              .then(response => {
                this.loading = false;
                this.show=false;
                this.$store.dispatch('showSnackBar', {
                  text: response,
                  color: 'success'
                })
              }, error => {
                this.$store.dispatch('showSnackBar', {
                  text: error,
                  color: 'error'
                })
              })
          }

        }
      },
      confirmDeleteUser(){
        this.loading = true;
        if(this.userType === 'registered'){
          this.$store.dispatch('deleteRegisteredUser', this.userIndex.id)
            .then(response => {
              this.loading = false;
              this.show = false;
              this.$store.dispatch('showSnackBar', {
                text: response,
                color: 'success'
              })
            }, error => {
              this.$store.dispatch('showSnackBar', {
                text: error,
                color: 'error'
              })
            })
        }
        if(this.userType === 'invited'){
          this.$store.dispatch('deleteInvitedUser', this.userIndex.id)
            .then(response => {
              this.loading = false;
              this.show = false;
              this.$store.dispatch('showSnackBar', {
                text: response,
                color: 'success'
              })
            }, error => {
              this.$store.dispatch('showSnackBar', {
                text: error,
                color: 'error'
              })
            })
        }
      }

    },
    computed:{
      ...mapGetters(['userRoles', 'allRegisteredUsers', 'allRegisteredKiosks', 'isSuperAdmin']),
      roles(){
        let roleName = [];
        if(this.isSuperAdmin){
          roleName.push('Super Admin') 
        }
        this.userRoles.forEach(role => roleName.push(role.data.name));
        return roleName;
      },
      kiosks(){
        let kioskName = [];
        this.allRegisteredKiosks.forEach(kiosk => kioskName.push(kiosk.data().name))
        return kioskName;
      }
    },
    // mounted(){
    //   if(this.userRoles.length === 0){
    //     this.$store.dispatch('getUserRoles')
    //       .then((response) => {
    //         console.log(response)
    //       }, error => {
    //         console.error(error);
    //       })
    //   }
    //   if(this.allRegisteredKiosks.length === 0){
    //     this.$store.dispatch('getAllRegisteredKiosks')
    //       .then((response) => {
    //         console.log(response)
    //       }, error => {
    //         console.error(error);
    //       })
    //   }
    // }
  }
</script>