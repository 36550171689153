var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',{staticClass:"px-3",attrs:{"elevation":"0"}},[_c('v-card-title',{staticClass:"text-h4"},[_vm._v(" User Management "),_c('v-spacer'),_c('createNewUser')],1)],1),_c('v-card',{staticClass:"px-3 mt-3",attrs:{"elevation":"0"}},[_c('v-tabs',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',[_vm._v("Registered Users")]),_c('v-tab',[_vm._v("Invited Users")])],1),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',[_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search Users by Name","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"loading":_vm.loading,"headers":_vm.getHeaders,"items":_vm.users,"search":_vm.search},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item.email",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.email)+" ")]}},{key:"item.userRole",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.userRole)+" ")]}},{key:"item.assignedKiosks",fn:function(ref){
var item = ref.item;
return _vm._l((item.assignedKiosks),function(kiosk,index){return _c('div',{key:index},[(index === 0)?_c('v-chip',{attrs:{"small":""}},[_c('span',[_vm._v(_vm._s(kiosk))])]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text caption"},[_vm._v(" (+ more..) ")]):_vm._e()],1)})}},{key:"item.last_changed",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.last_changed))+" ")]}},{key:"item.createdOn",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.createdOn))+" ")]}},{key:"item.onlineStatus",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"dark":"","small":"","color":_vm.getColor(item.onlineStatus)}},[_vm._v(" "+_vm._s(item.onlineStatus)+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.isSuperAdmin)?_c('editAndDeleteUser',{attrs:{"userType":"registered","userIndex":item}}):_vm._e()]}}])})],1),_c('v-tab-item',[_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search Users by Name","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"loading":_vm.loading,"headers":_vm.invitedUsersHeaders,"items":_vm.allInvitedUsers,"search":_vm.search},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.data().name)+" ")]}},{key:"item.email",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.data().email)+" ")]}},{key:"item.userRole",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.data().userRole)+" ")]}},{key:"item.createdOn",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.data().createdOn))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('editAndDeleteUser',{attrs:{"userType":"invited","userIndex":item}})]}},{key:"item.inviteLink",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"small":"","color":"primary","text":""},on:{"click":function($event){return _vm.copyLink(item)}}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v("mdi-content-copy")]),_vm._v(" Copy Link ")],1),_c('input',{attrs:{"type":"hidden","id":'user' + item.id},domProps:{"value":_vm.registrationLink + item.id}})]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }